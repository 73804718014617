/* toolbar styles */

.dark-mode .ql-toolbar {
    background-color: #2d3748; /* set the background color for the toolbar */
    color: #fff; /* set the text color for the toolbar */
  }
  
  .dark-mode .ql-toolbar .ql-stroke {
    fill: none;
    stroke: #fff;
  }
  
  .dark-mode .ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
  }
  
  .dark-mode .ql-toolbar .ql-picker {
    color: #fff;
  }
  
  /* placeholder text styles */
  .dark-mode .ql-editor::before {
    color: #a0aec0; /* set the color for the placeholder text */
  }
  
/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1400; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.48); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
      width: 33%;
      margin-right: 11%;
     
      background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .videoFileUploadBtn {
    margin-top:12px;
  }

  .videoFileInput {
    margin-top:12px;
  }
  .modelHeading {
    margin-bottom:12px;
  }
  .videoUrlTextBox {
    border: 1px solid gray;
    margin-top: 12px;
  }
  .error {
  color: red;
  }
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .button {
      background-color: #4CAF50 !important; /* Green */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      width: 40%;
      margin-right: 26px;
    }

    /* Date 17-05-23 for model popup css   */
    .modal-content.mcp_p {
      width: 60%;
    }
    .custom_upload {
      display: flex;
      height: 86px;
      border: 2px dashed #c4c4c4;
      position: relative;
  }
  .custom_upload:after {
    position: absolute;
    /* height: 30px; */
    /* width: 100%; */
    content: "Click here to upload file";
    background: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #787878;
}
.videoFileInput {
    margin-top: 0;
    opacity: 0;
    position: relative;
    z-index: 3;
    width: 100%;
}

.videoUrlTextBox:focus-visible {
  border: 0px;
  border-bottom: 1px;
  outline-color: blue !important;
 
}

.btnupload {
  text-align: center!important;
}
.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}


.btnupload button {
  background: #eaeaea!important;
}
/**/
.rdw-image-modal-btn {
  padding: 2px 6px;
  font-size: 14px;
  color: #333;

}
.inputimghw 
{
  padding: 0 10px;
  height: 28px;
  margin: 10px;
}
.inputimghwupload 
{
  padding: 0 10px;
  height: 28px;
  margin-top:20px;
  margin-right:10px;
}
/**/ 
.videoUrlTextBox {
  border: 0px;
  margin-top: 12px;
  border-bottom: 1px solid #bdbdbd;
  width: 100%;
  padding: 4px 0;
  margin-bottom: 4px;
}
.imageHWContainer {
    justify-content: space-between;
    margin-bottom: 10px;
}
blockquote {
display: block !important;
margin-block-start: 1em !important;
margin-block-end: 1em !important;
margin-inline-start: 40px !important;
margin-inline-end: 40px !important;
}
.cannedNotes pre {
  width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-indent-1 {
  padding-left:3em
}
.ql-indent-2 {
  padding-left:6em
}
.ql-indent-3 {
  padding-left:9em
}
.ql-indent-4 {
  padding-left:12em
}
.ql-indent-5 {
  padding-left:15em
}
.ql-indent-6 {
  padding-left:18em
}
.ql-indent-7 {
  padding-left:21em
}
.ql-indent-8 {
  padding-left:24em
}
.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align:justify
}
.ql-align-right {
  text-align:right
}
.ql-tooltip.ql-editing {
  left:28px !important;
  z-index:999999
}
.ql-tooltip {
  z-index:999999;
  left:28px !important;

}
.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  /* padding-left: 0.5em !important;*/
}

.chakra-ui-dark .ql-picker-options{
background-color :#2D3748 !important;
}
.chakra-spinner {
  z-index: 9999 !important;
  /* position: absolute !important; */
}

.ql-mention-list-container {
  height: auto;
  max-height: 200px; 
  overflow: auto; 
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.quill-mention-list {

position: absolute;
background-color: #fff;
min-width: 200px;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
z-index: 1;
}
.ql-mention-list-item {
  padding: 3px;
  text-decoration: none;
  display: block;
  color:blue;

}
.ql-mention-list-item li:focus,
.ql-mention-list-item li:focus-within {
  background-color: red;
  outline: 2px solid blue;
  /* Add any other styles to highlight the item, such as color or border */
}
.mention {
  color: blue;
}
.chakra-ui-light .ql-mention-list-item.selected {
  background-color: #f5f5f5;
}
.chakra-ui-dark .ql-mention-list-item.selected {
  background-color: #CCCCB4;
}

.chakra-ui-light td .rdw-mention-link {
  color: black !important;
}
.chakra-ui-dark td .rdw-mention-link {
  color: white !important;
}
.chakra-ui-dark .ql-snow .ql-picker{
  color: white !important;
  /* background-color: #2d3748;*/
}

.chakra-ui-dark .ql-mention-list-container.ql-mention-list-container-bottom {
  background-color: #2d3748;
}

.autocomplete .parent:hover .child {
  visibility: visible;
  opacity: 1;
}

.autocomplete .child {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
/**
 *  PLEASE DO NOT ADD GLOBAL CSS UNLESS
 *  IT IS ABSOLUTELY NECESSARY TO
 *
 *  We should be using Chakra components, pre-defined styles
 *  and themes as much as possible.
 *
 *  If you do add a style, please document why it needs CSS here,
 *  and when we can refactor it.
 *
 */

/**
 * For rich-text editor, it is too complicated to convert the HTML/CSS
 * of the toolbar to Chakra right now
 *
 */

.rdw-image-modal-upload-option-label {
    word-break: break-all;
}
.textClass div:nth-child(1) {
    z-index: auto !important;
}
.rdw-editor-toolbar {
    background: inherit !important;
    font-size: 12px;
    border: unset !important;
}
.rdw-editor-toolbar img {
    width: 10px;
}
/* .rdw-editor-main {
    max-height: 350px;height: auto;
} */
/* .rdw-editor-main .DraftEditor-editorContainer {
    max-height: 212px !important;
    overflow: scroll;
} */
/* react-searchable-dropdown css  third party plugin */

/* react-searchable-dropdown css third party plugin */

/* Webchat css  */
div[role='log'] ul {
    padding: 15px !important;
    font-size: 14px;
}
div[role='complementary'] .main {
    position: relative;
    min-height: 50px;
}
div[role='complementary'] .main form {
    background: #f5f5f5;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 5px;
    padding: 11px 0;
    border-radius: 50px;
    overflow: hidden;
}
div[role='complementary'] .main form input[type='text'] {
    background: #f5f5f5;
    padding: 0 36px;
}
div[role='complementary'] .main div:first-child {
    position: absolute;
    left: 17px;
    z-index: 11;
    top: 12px;
}
div[role='complementary'] .main div:last-child {
    position: absolute;
    right: 4px;
    z-index: 11;
    top: 12px;
}
.webchat__bubble__content {
    padding: 0 10px;
    max-width: max-content;
    display: flex;
    align-items: center;
}
div[role='complementary'] button.css-2m2878 {
    font-size: 12px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
}
div[role='complementary'] .ac-textBlock {
    color: #787878 !important;
}
div[role='complementary'] {
    margin-left: 10px;
    margin-right: 10px;
}
div[role='complementary'] .webchat__bubble__content img {
    position: relative !important;
}
.pulse {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e74c3c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(231, 76, 60, 0.5);
    animation: pulse 1.4s infinite;
}
.pulse:hover {
    animation: none;
}
.react-dropdown-select-dropdown {
    width: 100% !important;
    border: none !important;
    /* color: #333 !important; */
}
@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(231, 76, 60, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
    }
}
:root :focus {
    z-index: 1;
    box-shadow: none !important;
}

.react-dropdown-select-dropdown-handle {
    position: absolute;
    right: 8px;
}
.react-dropdown-select-content span {
    width: calc(100% - 30px);
    margin: 0 5px 2px 0px !important;
}
.react-dropdown-select-option-remove {
    width: 22px !important;
    background: #e74c3c;
}
.rdw-editor-wrapper .rdw-dropdown-optionwrapper:hover {
    background-color: #0074d9;
    color: #fff;
}

.rdw-editor-wrapper .rdw-dropdown-optionwrapper {
    background: #0074d9;
    color: #fff;
}
.rdw-editor-wrapper .rdw-dropdown-selectedtext {
    color: #000;
}
.rdw-editor-wrapper .rdw-dropdownoption-default.rdw-dropdownoption-active,
.rdw-dropdownoption-default.rdw-dropdownoption-highlighted {
    background-color: rgba(255, 255, 255, 0.8);
}
.rdw-editor-wrapper .rdw-dropdown-wrapper {
    height: 21px !important;
}

/* date picker css */
.react-datepicker-wrapper .react-datepicker__input-container input {
    background: transparent;
    border: 1px solid #ccc;
    height: 28px;
    padding: 4px;
}

/* Slider Css */
.container-slider {
    max-width: 100%;
    height: calc(100vh - 36px);
    margin: 10px auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
@media screen and (max-width: 700px) {
    .container-slider {
        margin: 100px 10px 0;
    }
}
.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}
.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.active-anim {
    opacity: 1;
}

.btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.prev {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
    position: absolute !important;
    background-color: gray !important;
}
.next {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
    position: absolute !important;
    background-color: gray !important;
}
/* Slider Css */

.resizer-elm > div {
    /* background: #0056D3; */
    width: 2px !important;
    border-radius: 10px;
    height: 98% !important;
    right: -6px !important;
    top: 7px !important;
    cursor: ew-resize !important;
}
.resizer-elm > div:hover {
    background: #0056d3;
    cursor: ew-resize !important;
}
.noteList a {
    color: blue;
}
.rdw-option-wrapper {
    color: black;
}
.rdw-image-modal {
    color: black;
    left:unset;
    right:2px;
}
/* .rdw-image-modal-btn:nth-of-type(2) {
  display: none;
} */
.dynamic-field-val span.react-dropdown-select-option {
    width: auto;
}
.dynamic-field-val .react-dropdown-select {
    width: 235px;
}
.react-dropdown-select .react-dropdown-select-loading:after {
    margin-right: 20px;
}
.webchat__bubble__content span {
    width: auto !important;
}

.chakra-collapse {
    overflow: visible !important;
}

.css-o36nok-DropDown {
    top: 43px !important;
}

/* .react-dropdown-select-dropdown{
  top: 43px !important;
} */


.dropdown-multiselect div span {
    width: auto !important;
}

.css-3tw5vh .public-DraftStyleDefault-block{
  color: inherit !important;
}

.chakra-ui-dark .rdw-link-modal{
  background-color: #1d1d1d;
}

#linkTitle{
  color: #000;
}

#linkTarget{
  color: #000;
}

.rdw-link-modal{
  height: auto !important;
  box-shadow: 10px 10px 40px -11px rgb(231 232 245 / 80%) !important;
  border-radius: 10px !important;
  border: 1px solid #f1f1f1 !important;
}

button.rdw-link-modal-btn:nth-child(2){
  background: transparent !important;
  border: 1px solid #e3e3e3 !important;
  color: #999 !important;
}

button.rdw-link-modal-btn:nth-child(1){
  background: #90cdf4 !important;
  border: 1px solid #90cdf4 !important;
  color: #fff !important;
}

.rdw-link-modal-btn{
  height: 26px !important;
}

button.rdw-link-modal-btn:disabled{
  background: #ece9e9 !important;
  border: 1px #ece9e9 solid !important;
  color: #999 !important;
  font-weight: 600 !important; 
}


.webchat__bubble__content span{
  width: auto !important;
} 

/*diff checker css overwrite for dark theme  */
.css-1n5o7vh-diff-container {
  background: inherit !important;
}
.css-17uc4if-title-block {
  background: inherit !important;
}
.css-17uc4if-title-block .css-o1u8iu-content-text {
  color: inherit !important;
}

.css-10regm7-empty-line {
  background-color: inherit !important;
}
.css-1ks9aww-gutter {
  background: inherit !important;
}
.css-h84nzi-line-number {
  color: inherit !important;
}
.css-1n5o7vh-diff-container {
  border: 1px solid #e2e2e2 !important;
}
.css-1caozzd-split-view .css-vl0irh-content {
  border-right: 1px solid #e2e2e2 !important;
}
.css-1ks9aww-gutter.css-cnnxkz-diff-added {
  opacity: .5;
  color: inherit;
}
.css-1ks9aww-gutter.css-rq9a2a-diff-removed {
  color: inherit;
  opacity: .5;
}
.css-ye9jxm .public-DraftStyleDefault-block {
    color: inherit !important;
}
.rdw-suggestion-dropdown {
    background: inherit;
}
.chakra-ui-dark .elementToProof span, .chakra-ui-dark .elementToProof div {
    color: #fff !important;
    background: inherit !important;
}
.chakra-ui-dark #Signature b a{color: #034FBD !important;}
.chakra-ui-dark #Signature b {background: transparent !important;}
.elementToProof p {
    background: inherit !important;
    color: inherit;
}
.chakra-ui-dark .DraftEditor-root span {
    background: inherit !important;
    color: inherit;
}
.css-3tw5vh .public-DraftStyleDefault-block span a{
    color: #034FBD !important;
}
.rdw-mention-link {
    background-color: inherit
}


.chakra-ui-dark .x_elementToProof span {
    background-color: inherit !important;
    color: inherit !important;
}
.elementToProof {
     color: inherit !important
}
.chakra-ui-dark #Signature div {
    background-color: inherit !important;
    color: inherit !important
}
.chakra-ui-dark #x_Signature div {
    background-color: inherit !important;
    color: inherit !important
}
.chakra-ui-dark #Signature span {
    background-color: inherit !important;
} 
.chakra-ui-dark #x_Signature b a{color: #034FBD !important;}
.chakra-ui-dark #x_Signature b {background: transparent !important;}

.rdw-suggestion-dropdown{
    min-width: 200px !important;
}

.service-team-dropdown .react-dropdown-select {
    width: 12rem;
}

.chakra-ui-dark .react-dropdown-select-item{
    color: #fff !important;
} 

/* .quickNote-description > p, h1, h3, h4, h5, h6{
    width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
} */

.quickNote-description ul,
.quickNote-description li 
{
    width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}

.quickNote-description ul,
.quickNote-description li 
{
    width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}

.chakra-ui-dark .progress-bar{
    border: 1px solid #3f444e;
}


.chakra-ui-dark .react-dropdown-select-item{
    color: #fff !important;
    background-color: #2D3748 !important;
    border-color: #2D3748 !important;
} 

.chakra-ui-dark .react-dropdown-select-item:hover{
    color: #fff !important;
    background-color: #4a5468 !important;
} 

.chakra-ui-dark .react-dropdown-select-item-selected, 
.chakra-ui-dark .react-dropdown-select-item-selected:hover{
    background-color: #0074D9 !important;
}

.chakra-ui-dark .chakra-form-control .chakra-skeleton p {
    color: inherit !important;
}

.monaco-editor .no-user-select .mac  .showUnused .showDeprecated .vs{
    width: 30vw !important;
    margin-top: 5vh !important;
    }

.WordSection1 > *{
    color: inherit !important;
}

.MsoNormal{
    color: inherit !important;
}

span.wysiwyg-mention{
    color: #556fff !important;
    font-weight: bold;
}

.chakra-ui-dark .chakra-form-control .chakra-skeleton p {
    color: #fff !important;
}

.chakra-ui-dark  .css-of6tae  {
    background-color: #2D3748 !important;
}
.chakra-ui-dark .chakra-form-control .chakra-skeleton p {
    color: #fff !important;
}
.css-k8qd60{
    padding: 10px !important;
}
.chakra-ui-dark .higilightEle {
    background-color: #0074D9 !important;
    color: #fff!important;
}
.higilightEle {
    background-color: #0074D9 !important;
    color: #fff !important;
}
.chakra-ui-dark .assignedListBg {
    color: #fff !important;
    background-color: #2D3748 !important;
    border-color: #2D3748 !important;
}
.assignedListBg:hover{
    background: rgba(0, 116, 217, 0.1);
    outline: none;
}
.chakra-ui-dark .assignedListBg:hover {
    background:  #4a5468 !important;
    outline: none;
}
.assigntomelabel {
    color: #034FBD;
}
.assigntomelabel:hover {
    text-decoration: underline;
}
.chakra-ui-dark .assigntomelabel {
    text-decoration: #034FBD;
}
.chakra-ui-dark .assigntomelabel:hover {
    text-decoration: underline;
}    
.inbox-table-view .react-draggable{
    width: initial !important;
}
.isChecked .react-draggable{
    width: 68% !important;
}
.inbox-table-view .search-filter-wrapper{
    display: flex !important;
    flex-direction: row !important;
    width: 60% !important;
}
.inbox-table-view .my-box{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
}
.inbox-table-view .notification-container{
    width: 20% !important;
}
.inbox-table-view .notification-container ul{
    --chakra-wrap-x-spacing: none !important;
}
.inbox-table-view .notification-container ul li{
   align-items: center !important;
}
.radio-container .chakra-radio input {
    display: none;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR{
    margin-left: 30px;
}

.dropDown-filter-renderer > div {
    /* styles for the direct child element with class .dropDown-filter-renderer */
    z-index: 2; /* Replace 100 with the desired value */
}
.filterDatePicker{width: 192px;}

.queue-dropdown span[role="listitem"]{
    width: fit-content;
}

.css-r6z5ec {
    z-index: 10 !important;
}

.emailEditor table{width: 100%!important;}


.attachment input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
 

.chakra-ui-dark .editorfooter {
    background-color: #2d3748;
}

.chakra-ui-dark .editorfooter .editormenubtn {
    background: transparent !important;
}

.editorfooter {
    background-color: #e2e8f0;
}

.chakra-ui-dark .editorfooter .listItm {
    background-color: rgb(226, 232, 240);
    color: #808080;
}



.editorfooter .listItm {
    background-color: #e2e8f0 !important;
    color: #808080;
}

.pop_over {
    position: absolute !important;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(-8px, 48px) !important;
}

.infobtn  {
    /* inset: -96px;
    transform: translate(-7px, 48px);
    position: relative; */
    left: -21px;
    
}

/* .editorfooter .listItm:hover {
    background-color: #fff !important;
} */



.rdw-link-modal  {
    left: -80px;
}

.tableView {
    padding-bottom: 2rem !important;
}

.tableView .asc_icon {
    position: relative !important;
    top: 3px !important;
    color: #a0a8b4;
}

.tableView .desc_icon {
    position: relative !important;
    top: -4px !important;
    color: #a0a8b4;
}

.tableView .asc_icon:hover {
    color: #00000080
}

.tableView .desc_icon:hover {
    color: #00000080
}
.infoClass{
    margin:0 1% !important
}
.chakra-stack .chakra-popover__popper{
    z-index: 100;
}

.notesForDark a:not(:has(img)):not(:empty) {
    color: #556fff !important;
    font-weight: bold;
    background-color: #ecedf0;
    border: 1px solid transparent;
    border-radius: 20px
}
iframe{height: 0px !important;}


/* PRD-969 */
.agent-container{color:#6b7280;}
.ticket-tabs {
	position: relative;
	display: inline-block;
}
.ticket-tabs:hover{
    background:none;
    color:#034FBD;
}

.ticket-tabs:hover .action-btn-left, .ticket-tabs:hover .action-btn-right {
	display: block;
}

.ticket-tabs .action-btn-left {
    padding-top: 3px;
    padding-right: 0px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    left: 0;
    top: 0;
	display: none;
}

.ticket-tabs .action-btn-right {
    padding-top: 3px;
    padding-right: 2px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    right: 0;
    top: 0;
	display: none;
}
.ticket-tabs .action-btn-right-simple {
    padding-top: 3px;
    padding-right: 2px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    right: 0;
    top: 0;
}

.ticket-tabs .image-btn-left {
    padding-top: 3px;
    padding-right: 0px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    left: 0;
    top: 0;
}

.ticket-tabs .image-btn-right {
    padding-top: 3px;
    padding-right: 0px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    right: 0;
    top: 0;
}

.schedule-datepicker input{
    border-radius: 5px;
    width: 100%;
}

.schedule-timepicker input {
    border-radius: 5px;
    width: 100%;
}
.react-datepicker-wrapper{
    width: 100%;
    font-size: 10px;
}

span.data-mention{
    color: #556fff !important;
    font-weight: bold !important; 
    padding: 1px 4px !important;
}



.divScroll {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.2);
        border-radius: 5px;
      }
    scrollbar-width: none;
  }
.divScroll:hover {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.2);
      border-radius: 5px;
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,0.2) rgba(0,0,0,0.1);
  }
  
  .ticket_detail_container .listCheck{display:none}
  .ticket_detail_container:hover .listCheck{
	display: block;
}

.queue-dropdown .react-dropdown-select-dropdown {
    top: 43px !important;
}

.react-dropdown-select-content span {
    width: auto !important;
}
.emailSignEditor{
    border: 1px solid lightgrey;
}

.serviceTeamSelect .react-dropdown-select-content{
    justify-content: center !important;
}
.css-5skhoe{
    height: 367px !important;
}
.emailDescCl ol{
    padding: revert !important;
}
.emailDescCl ul{
    padding: revert !important;
}
.emailDescCl div{
    width: fit-content !important;
}
.emailBodyLink{
    color: #4f8af5 !important;
}
/* .css-1h24s7m { */
/* overflow: scroll !important; */
/* } */
/* category component blank space fix  */
/* .css-td0epc{
    overflow: scroll !important;
}
.categoryScrollFix{
    overflow: scroll !important;
}  */

.iframe-div{
    border: 2px solid #D5CC5A; overflow: hidden; margin: 15px auto; max-width: 575px;
}
.iframe-div-content{border: 0px none; margin-left: -36px; height: 812px; margin-top: -486px; width: 650px;}
.watchersList .customDropdown .react-dropdown-select-option-label{
    width: 170px !important;
}
.watchersList .customDropdown .react-dropdown-select-option{
    width: 180px !important;
}
.watchersList .customDropdown .react-dropdown-select-option-remove{
    width: 10px !important;
    border-radius: 0 !important;
}
.watchersList .customDropdown .react-dropdown-select-option .react-dropdown-select-option-label {
    width: 150px !important;
}

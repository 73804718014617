iframe {
    height: fit-content !important;
}

.ql-toolbar .ql-disabled {
    opacity: 0.5;
    cursor:not-allowed !important;
}

.ql-toolbar .ql-disabled svg{
    cursor: not-allowed !important;
}

.autocomplete .parent:hover .child {
    visibility: visible;
    opacity: 1;
}
  
.autocomplete .child {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}
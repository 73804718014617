#exception {
    border: 2px solid transparent;
    background: linear-gradient(#F4F4F4, #f4f4f4) padding-box, linear-gradient(var(--angle),
            #fff,
            #2563EB) border-box;
    animation: 5s rotate ease infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.convo-wrapper.clearfix .ac-pushButton {
    width: 100% !important;
}

.convo-wrap.from-bot button.ac-pushButton {
    border: 1px solid #e2e2e2 !important;
    border-radius: 50px !important;
    color: #c4c4c4 !important;
    width: 157px;
    margin: 0px auto;
    text-align: center;
    padding: 5px 6px;
    font-size: 13px;
    color: #999999 !important;
}

.convo-wrap.from-bot button.ac-pushButton:hover {
    background-color: #e85a5b !important;
    color: #fff !important;
    transition: all 0.3s;
    border: 1px solid #e85a5b !important;
    text-decoration: none !important;
}

/* .convo-wrap.from-bot button.ac-pushButton {
	margin-bottom: 13px;
} */

.convo-wrapper.clearfix p .ac-pushButton {
    width: 100% !important;
}

input.ac-input,
select.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
    height: 35px;
    border-radius: 5px;
    border: 0px;
    color: #6f7d8c;
    font-size: 14px;
    padding: 0px 10px;
    background-color: #fff;
}

.ac-input {
    border-radius: 4px;
    border: 1px solid #e2e2e2 !important;
    padding: 6px 20px !important;
    position: relative !important;
    color: #6f7d8c;
}

.back-btn svg {
    font-size: 28px;
}